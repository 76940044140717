<template>
  <md-field class="field-upload">
    <md-file v-model="evt" multiple />
  </md-field>
</template>
<script>
export default {
  props: ["onFilesSelected"],
  data() {
    return {
      evt: [],
    };
  },
  watch: {
    evt() {
      this.$emit("onFilesSelected", this.evt.target.files);
    },
  },
};
</script>
