<template>
  <div class="msg-area">
    <div class="mail-input">
      <div><h6>From</h6></div>
      <TextInput type="text" v-model="Message.From" :isdisabled="true" />
    </div>
    <div class="mail-input">
      <div><h6>To</h6></div>

      <search-text-input
        name="users"
        label="users"
        :items="searchUserResults.value"
        v-model="Message1.postData.to"
      />
    </div>
    <div class="mail-input mail-subject">
      <TextInput
        type="text"
        v-model="Message1.postData.subject"
        @submit="SendMessage"
        placeholder="Add a Subject"
      />
    </div>
    <div class="mail-input">
      <textarea
        v-model="Message1.postData.body"
        type="textarea"
        @submit="SendMessage"
        placeholder="Message..."
      ></textarea>
    </div>
    <div class="msg-action">
      <spinner
        slot="footer"
        style="margin-bottom: 10px"
        :diameter="25"
        v-if="progress.isSending"
      ></spinner>
      <md-button
        v-else
        slot="footer"
        class="md-raised md-primary send"
        @click="onSendMessage()"
        ><i class="fa fa-paper-plane"></i> Send
      </md-button>
      <md-button slot="footer" class="btn-outline" @click="goToBackCompose"
        ><i class="fa fa-trash-alt"></i> Discard
      </md-button>

      <file-upload-component-handler
        ref="fileUpload"
        :options="{
          feature: Feature.Messages,
          featureId: Message1.postData.id,
          category: '',
        }"
        v-slot="{ handleFilesSelected }"
      >
        <file-input-component @onFilesSelected="handleFilesSelected" />
      </file-upload-component-handler>
    </div>
  </div>
</template>

<script>
import FileUploadComponentHandler from "../../handlers/common-handlers/FileUploadComponentHandler";
import TextInput from "../../components/controls/TextInput.vue";
import Feature from "../../constants/Features";
import FileInputComponent from "../../components/controls/FileInputComponent.vue";
import { Warning } from "../../models/SwalMessages";

export default {
  components: { TextInput, FileUploadComponentHandler, FileInputComponent },
  data() {
    return {
      parentId: this.$route.params.id,
      toId: this.$route.params.fromid,
      Feature,
      files: "",
      Message: {
        From: "",
      },
      file: [],
    };
  },
  created() {
    this.from();
    this.Message1.postData.parentMsgID = this.parentId;
    this.Message1.postData.to = this.toId;
  },
  methods: {
    from() {
      this.isedit = true;
      this.Message.From = this.app.userProfile.email;
    },
    goToBackCompose() {
      this.Message1.postData.composemsg = false;
      this.Message1.postData.to = "";
      this.Message1.postData.body = "";
      this.Message1.postData.subject = "";
    },
    onSendMessage() {
      if (this.Message1.postData.to == this.Message.From) {
        let warning = new Warning();
        warning.title = "Warning";
        warning.text = "Sender and recipient Email Id cannot be same ";
        warning.confirmButtonClass = "md-button md-info";
        this.showWarning(warning);
      } else if (this.Message1.postData.to == null) {
        let warning = new Warning();
        warning.title = "Warning";
        warning.text = "This message must have atleast one recipient";
        warning.confirmButtonClass = "md-button md-info";
        this.showWarning(warning);
      } else if (this.Message1.postData.body == "") {
        let warning = new Warning();
        warning.title = "Warning";
        warning.text = "Please enter a message";
        warning.confirmButtonClass = "md-button md-info";
        this.showWarning(warning);
      } else {
        this.SendMessage();
      }
    },
  },
  mounted() {
    this.Message1.fileUploadRef = this.$refs.fileUpload;
  },
  inject: [
    "Message1",
    "SendMessage",
    "AddAttachment",
    "app",
    "searchUserResults",
    "showWarning",
    "progress",
  ],
};
</script>
